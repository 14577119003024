.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@font-face {
    font-family: 'East Sea Dokdo';
    src: url('./Assets/Fonts/EastSeaDokdo-Regular.ttf') format('truetype');
    font-display: swap;
}

* {
    font-size: 100%;
    font-family: 'East Sea Dokdo', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}


.inner-shadow-custom {
    -webkit-box-shadow: inset 0px 10px 30px -25px rgba(0,0,0,1);
    -moz-box-shadow: inset 0px 10px 30px -25px rgba(0,0,0,1);
    box-shadow: inset 0px 10px 30px -25px rgba(0,0,0,1);
}