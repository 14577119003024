@import "~react-image-gallery/styles/css/image-gallery.css";

body {
  background-color: black;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.image-gallery {
  width: 100%;
  height: 100%;
}
.image-gallery-thumbnail {
  display: inline-flex;
  justify-content: center;
  align-content: center;
}

.image-gallery-thumbnail-image {
  max-height: 6rem;
  max-width: 6rem;
  object-fit: contain;

}

.image-gallery-thumbnail-inner {
  display: flex;
  justify-content: center;
  /*align-content: center;*/
  /*flex-wrap: wrap;*/
  height: 6rem;
  width: 6rem;
}

.image-gallery-slide {
  height: calc(100vh - 7rem);
}